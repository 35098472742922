.animate-repeat {
  -webkit-transition: 0.5s ease-in-out all;
  transition: 0.5s ease-in-out all;

  /* transform: scale(1.2); */
  z-index: 1;
  /* opacity: 0.6; */
}
//   .animate-repeat-appear,
.animate-repeat-enter,
.animate-repeat-exit {
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

//   .animate-repeat-appear,
.animate-repeat-exit,
.animate-repeat-active-exit,
.animate-repeat-enter {
  opacity: 0;
  max-height: 0;
}

//   .animate-repeat-appear,.animate-repeat-active-appear,
.animate-repeat-exit,
.animate-repeat-enter,
.animate-repeat-active-enter {
  opacity: 1;
  max-height: 40px;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

// body {
// 	font-family: sans-serif;
//   }

//   .container {
// 	position: relative;
//   }

//   .display {
// 	position: relative;
// 	z-index: 1;
// 	width: 120px;
// 	height: 40px;
// 	background-color: #5a564c;
// 	border: none;
// 	border-radius: 5px;
// 	outline: none;
// 	cursor: pointer;
// 	transition: background-color 350ms;
//   }

//   .display:active {
// 	background-color: #000000;
//   }

//   .list-body {
// 	position: absolute;
// 	top: 45px;
// 	z-index: 1;
// 	box-sizing: border-box;
// 	width: 200px;
// 	padding: 0 20px;
// 	overflow: hidden;
// 	background-color: #9e8949;
// 	border-radius: 5px;
//   }

//   .list {
// 	padding: 0;
// 	list-style-type: none;
//   }

//   .list-item {
// 	padding: 5px 0;
//   }

//   .list-item--active {
// 	color: blue;
// 	transition: color 1500ms;
//   }

//   /* This fires as soon as the element enters the dorm */
//   .list-transition-enter,
//   .list-transition-appear {
// 	/*We give the list the initial dimension of the list button*/
// 	top: 0;
// 	width: 120px;
// 	max-height: 40px;
// 	color: transparent;
// 	background-color: #5a564c;
//   }
//   /* This is where we can add the transition*/
//   .list-transition-enter-active,
//   .list-transition-appear-active {
// 	top: 45px;
// 	width: 200px;
// 	max-height: 200px;
// 	background-color: #9e8949;
// 	transition: all 400ms;
//   }

//   /* This fires as soon as the this.state.showList is false */
//   .list-transition-exit {
// 	top: 45px;
// 	width: 200px;
// 	max-height: 200px;
// 	background-color: #9e8949;
//   }
//   /* fires as element leaves the DOM*/
//   .list-transition-exit-active {
// 	top: 0;
// 	width: 120px;
// 	max-height: 40px;
// 	color: transparent;
// 	background-color: #5a564c;
// 	transition: all 400ms;
//   }
