// .clearfix {
// 	&:before,
// 	&:after {
// 	  display: table; // 2
// 	  content: " "; // 1
// 	}
// 	&:after {
// 	  clear: both;
// 	}
//   }

.SearchForm {
  margin-bottom: 3em;
  display: grid;
  max-width: 700px;
}
@media screen and (min-width: 526px) {
  .SearchForm {
    grid-template-columns: 1fr auto;
    //height: 3em;
    grid-gap: 1em;
  }
}
@media screen and (max-width: 525px) {
  .SearchForm {
    grid-template-rows: 1fr 1fr;
    height: 6.5em;
    grid-gap: 0.5em;
  }
}
.SearchForm-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  border: 1px solid #99a9bb;
  padding: 0.8em;
}
.SearchForm-input::-webkit-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.SearchForm-input:-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.SearchForm-input::-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.SearchForm-input::placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.SearchForm-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  border: none;
  position: relative;
  background: #002856;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-indent: 0.15em;
  padding: 0 2em;
  cursor: pointer;
}
.SearchForm-submit:hover {
  background-color: #0091b3;
}
