.container {
  background: white;
  box-shadow: 0 2px 20px rgba(0, 40, 86, 0.25);
  margin: 3em auto;
  padding: 1.5em;

  h2 {
    font-size: 1.1rem;
    font-weight: normal;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-transform: uppercase;
  }
}
